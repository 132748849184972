
import { EventBus } from '@/plugins/eventBus'
import axios, { AxiosError, AxiosResponse } from 'axios'
import VueApexCharts from 'vue-apexcharts'
import Vue from 'vue'

Vue.use(VueApexCharts)

interface Space {
  id: number,
  name: string
}

export default Vue.extend({
  name: 'SpaceStatistics',
  components: {
    VueApexCharts
  },
  data: () => ({
    loading: true,
    spaces: [],
    selectedSpaces: [],
    stats: [], // generated statistics
    selectedYear: null as number|null, // selected 
    years: [] as Array<number>,
    month: null, // selected month
  }),
  methods: {
    /**
     * Download XLSX file of selected spaces
     */
    getXLSX() {
      if (this.selectedSpaces.length == 0) {
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.admin.statistics.please_select_one_space'), color: 'error' })
        return
      }

      this.loading = true
      axios.get(
        '/v1/admin/spaces/statistics?xlsx=true&year=' + this.selectedYear + '&id=' + this.selectedSpaces.map((space: Space) => space.id).join(','),
        { responseType: 'blob' }
      )
      .then((response: AxiosResponse) => {
        // Create a new Blob from the response of the apu
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        // Create an element containing an URL representing the Blob
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        // Set up the right headers and assign the file name
        let headerLine = response.headers['content-disposition']
        let startFileNameIndex = headerLine.indexOf('"') + 1
        let endFileNameIndex = headerLine.lastIndexOf('"')
        let fileName = headerLine.substring(startFileNameIndex, endFileNameIndex)
        // Set up the download
        link.download = fileName
        link.click()
        // Cleanup 
        URL.revokeObjectURL(link.href)
      }).catch((error: AxiosError) => {
          EventBus.$emit('snackbar', { axiosError: error })
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * Select or unselect all spaces
     */
    selectAllSpaces() {
      if (this.selectedSpaces.length == this.spaces.length) {
        this.selectedSpaces = []
      } else {
        this.selectedSpaces = this.spaces
      }
    },
    /**
     * Fetch spaces statistics and redraw charts
     */
    getStatistics() {
      if (this.selectedSpaces.length == 0) {
        EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.admin.statistics.please_select_one_space'), color: 'error' })
        return
      }

      this.loading = true
      this.stats = [] // empty stats data
      axios.get('/v1/admin/spaces/statistics?year=' + this.selectedYear + '&id=' + this.selectedSpaces.map((space: Space) => space.id).join(','))
      .then((response: AxiosResponse) => {
        this.stats = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    // configure year data
    let currentYear = new Date().getFullYear()
    this.years = [
      currentYear,
      currentYear - 1,
      currentYear - 2,
      currentYear - 3,
      currentYear - 4
    ]
    this.selectedYear = currentYear

    // retrieve spaces
    let recalls: Promise<AxiosResponse>[] = []

    axios.get('/v1/admin/spaces?page=1')
    .then((response: AxiosResponse) => {
      // store retrieved spaces
      this.spaces = response.data
      // prepare recalls if more pages are available
      let match = /page=(\d+)[^>]*>;\s*rel="last"/m.exec(response.headers.link)
      if (match && match[1] && parseInt(match[1]) > 1) {
        for (let i = 2; i <= parseInt(match[1]); i++) {
          recalls.push(
            axios.get(`/v1/admin/spaces?page=${i}`)
          )
        }
      }
    })
    .then(() => {
      // execute recalls if any
      Promise.all(recalls)
      .then((results) => {
        results.forEach((response: AxiosResponse) => {
          // concatenate each page retrieved spaces
          this.spaces = this.spaces.concat(response.data)
        })
      }).catch((error: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: error })
      }).finally(() => {
        this.loading = false
        this.selectedSpaces = this.spaces // select all spaces by default
      })
    }) 
  },
  computed: {
    /**
     * Return icon used on select all spaces v-autocomplete
     */
     selectAllSpacesIcon(): string {
      if (this.selectedSpaces.length == 0) {
        return 'mdi-checkbox-blank-outline'
      } else if (this.selectedSpaces.length == this.spaces.length) {
        return 'mdi-close-box'
      } else {
        return 'mdi-minus-box'
      }
    }
  }
})
