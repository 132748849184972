var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticStyle:{"z-index":"12"},attrs:{"items":_vm.spaces,"outlined":"","chips":"","small-chips":"","deletable-chips":"","label":_vm.$vuetify.lang.t('$vuetify.admin.statistics.spaces'),"multiple":"","item-text":"name","item-value":"id","return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.selectAllSpaces}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedSpaces.length > 0 ? 'primary' : ''}},[_vm._v(_vm._s(_vm.selectAllSpacesIcon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.admin.statistics.select_all_spaces')))])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.selectedSpaces),callback:function ($$v) {_vm.selectedSpaces=$$v},expression:"selectedSpaces"}}),_c('v-select',{staticStyle:{"z-index":"13"},attrs:{"items":_vm.years,"label":_vm.$vuetify.lang.t('$vuetify.admin.statistics.year'),"outlined":""},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.getStatistics()}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.admin.statistics.show_charts')))]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.getXLSX()}}},[_vm._v("XLSX")])],1)],1),_c('v-row',_vm._l((_vm.stats),function(stat,index){return _c('v-col',{key:index,attrs:{"md":"12","lg":"6","xl":"4"}},[_c('v-card',{attrs:{"elevation":"6","color":"primary"}},[_c('v-sheet',[_c('VueApexCharts',{attrs:{"type":"bar","options":{
                xaxis: {
                  categories: Object.keys(stat).slice(1)
                },
                theme: {
                  monochrome: {
                    enabled: true,
                    color: _vm.$vuetify.theme.currentTheme.primary
                  }
                },
              },"series":[{ data: Object.values(stat).slice(1), name: Object.values(stat).shift() }]}})],1),_c('v-card-text',{staticClass:"text--primary"},[_c('div',{staticClass:"text-h6 white--text"},[_vm._v(" "+_vm._s(Object.values(stat).shift())+" ")])])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }